import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { TbCurrencyRupee } from "react-icons/tb";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import PostApiCall from "../../Api/postApi";
import image from "../../assets/images/search-engine.png";
// import NoImage from "../../assets/images/NoImage.png";
import { Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import "./Skeleton.css";
const GalleryStyles = styled.div`
  .gallery__grid {
    display: grid;
    ${'' /* gap: 3rem; */}
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    justify-content: center;
    padding: 50px 0 0 0;
  }
  .gallery__title {
    font-size: 2rem;
    padding: 3%;
    text-align: center;
  }`;
const TopCities = () => {
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "#777f80",
    });
    var customer = localStorage.getItem("customerDetails");
    var CustomerDetails = JSON.parse(customer);
    // Notiflix.Loading.Dots("Please wait...");
    // Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    //
    PostApiCall.postRequest(
      {
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          Notiflix.Loading.remove();
          //
          setCartData(res1.data);
          setLoading(false);
        }
      });
  }, [])
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const CardBanners = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const rowSkeletons = 3;
  if (loading) {
    let rows = [];
    for (let index = 0; index < rowSkeletons; index++) {
      rows.push(
        <Container className='ms-4 mb-4'>
          <Skeleton height={400} />
        </Container>
      );
    }

    return (
      <>
        <SkeletonTheme color="#F5F5F5" highlightColor="#ffffff">
          <GalleryStyles className="gallery__grid">
            <div className="gallery__grid">{rows}</div>
          </GalleryStyles>
        </SkeletonTheme>
      </>);
  }

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <Card.Title className="mb-4">
            <h4 className="mb-0 shopping-cart-font">
              Items in Cart
            </h4>
          </Card.Title>
          {cartData.length != 0 ?
            <MultiCarousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={CardBanners}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
            >
              {
                cartData.map((data, i) => {
                  return (

                    <div className="mx-2">
                      <div className="text-center mx-lg-3 mx-2 me-4 me-md-3 me-sm-0 ">
                        <div className="service-wrapper shadow">
                          <Card className="services  border-0 fproduct ancrtag">
                            <Link
                              to={`${process.env.PUBLIC_URL
                                }/productdetail/${data.fld_variantname == null ? "" : data.fld_variantname.replace(
                                  /\s/g,
                                  "-"
                                )}/${data.fld_variantid}`}
                            >
                              <img variant="top" className='fproductimage' src={data.VariantImage} />
                            </Link>
                            <Card.Body className="bg-light">
                              <Link
                                to={`${process.env.PUBLIC_URL
                                  }/productdetail/${data.fld_variantname == null ? "" : data.fld_variantname.replace(
                                    /\s/g,
                                    "-"
                                  )}/${data.fld_variantid}`}
                              >
                                <Card.Title className="text-start wow animate__animated animate__fadeInUp">
                                  {data.fld_variantname}
                                </Card.Title>
                              </Link>
                              <div className="vertical-name wow animate__animated animate__fadeInUp">
                                {data.fld_categoryname}
                              </div>
                              <Card.Title className="vertical-price wow animate__animated animate__fadeInUp">
                                <TbCurrencyRupee /> {data.fld_website_discount_price}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </MultiCarousel>
            : <div className='row justify-content-center mt-5'>
              <div className='col-lg-6 col-sm-12 col-md-12' style={{ textAlign: "center" }}>
                <img src={image} className="not-available" />
                <h5 className='fw-bold'>Your shopping bag is empty.</h5>
              </div>
            </div>
          }

        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
export default TopCities;
