import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Offcanvas, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PostApiCall from "../Api/postApi";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import { store } from "../context/store";
// skeleton
import { BiSearch, BiSolidChevronDownCircle } from "react-icons/bi";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import Card1 from "../components/ProductCards/Card1";
import _ from "lodash";

const GalleryStyles = styled.div`
  .gallery__grid {
    display: flex;
    gap: 0rem;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    justify-content: center;
    padding: 0 3% 0 3%;
  }
  .gallery__title {
    font-size: 2rem;
    padding: 3%;
    text-align: center;
  }
  .item {
    margin: auto;
    border: 3px solid var(--gray-1);
  }
  .item__btns {
    display: flex;
    justify-content: space-between;
    button {
      font-size: 1.125rem;
      background-color: var(--gray-1);
      padding: 0.2rem 0.5rem;
      height: 3rem;
      border-radius: 8px;
      font-weight: bolder;
    }
  }
  .item-img {
    width: 304px;
    height: 140px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      object-fit: contain;
    }
  }
  .item-title {
    font-size: 1rem;
    height: 82px;
    text-align: center;
    margin-bottom: 1rem;
  }
  .item-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .item-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    width: 60px;
  }
  .item__btnadd {
    border: 2px solid var(--red-1);
    color: var(--red-1);
  }
  .item-price {
    font-size: 2.5rem;
    color: var(--blue-1);
    text-align: center;
    margin-bottom: 1rem;
  }
  .item__btnbuy {
    border: 2px solid var(--red-1);
    background-color: var(--red-1) !important;
    color: var(--gray-1);
  }
  .item-start {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid yellow;
    svg {
      font-size: 1rem;
    }
  }
  .skeleton {
    margin-bottom: 1rem;
  }
`;

const Productlist = ({ location }) => {
  const { newArrivals, setNewArrivals } = useContext(store);
  const [searchInput, setSearchInput] = useState(""); // stores user search input
  const [loading, setLoading] = useState(true);
  const [filterType] = useState([
    { label: "Price, High to Low", value: "Price, High to Low" },
    { label: "Price, Low to High", value: "Price, Low to High" },
    { label: "Alphabetically, A to Z", value: "Alphabetically, A to Z" },
    { label: "Alphabetically, Z to A", value: "Alphabetically, Z to A" },
  ]);
  const [sortButton, setSortButton] = useState([]);
  const [filter, setFilter] = useState([]);
  const { reloadSubCategory, setReloadSubCategory } = useContext(store);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const params = useParams();
  var login = localStorage.getItem("CustomerData");
  var logindetails = JSON.parse(login);
  var orderid = 0;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getSubCategory();
  }, [reloadSubCategory]);
  const getSubCategory = async () => {
    let processCategoryObj = {};
    if (window.location.pathname == "/shop") {
      processCategoryObj = {};
    } else {
      processCategoryObj = {
        subcategoryid: params.subcategoryid,
      };
    }
    PostApiCall.postRequest(processCategoryObj, "GetProductsCatgory").then(
      (results) => {
        results.json()?.then((obj) => {
          if (results.status == 200 || results.status == 201) {
            // ;
            setNewArrivals(obj.data);
            setLoading(false);
          }
        });
      }
    );
  };
  const rowSkeletons = 3;
  if (loading) {
    let rows = [];
    for (let index = 0; index < rowSkeletons; index++) {
      rows.push(
        <Container className="ms-5 mb-4">
          <Skeleton height={200} width={300} />
        </Container>
      );
    }

    return (
      <>
        <SkeletonTheme color="#F5F5F5" highlightColor="#ffffff">
          <GalleryStyles className="gallery__grid">
            <Skeleton height={200} />
            <div className="gallery__grid mt-4">{rows}</div>
          </GalleryStyles>
        </SkeletonTheme>
      </>
    );
  }
  const filteredData = newArrivals.filter((data) => {
    if (searchInput === "") {
      return true;
    }

    const lowerCaseSearchInput = searchInput.toLowerCase();
    const variantName = String(data.fld_variantname).toLowerCase();
    const discountPrice = String(data.fld_website_discount_price).toLowerCase();

    return (
      variantName.includes(lowerCaseSearchInput) ||
      discountPrice.includes(lowerCaseSearchInput)
    );
  });
  const SortByPriceHighToLow = () => {
    let sortedProductsDsc;
    sortedProductsDsc = newArrivals.sort((a, b) => {
      return (
        parseInt(b.fld_website_discount_price) -
        parseInt(a.fld_website_discount_price)
      );
    });
    setNewArrivals(sortedProductsDsc)
  };
  const SortByPriceLowToHigh = () => {
    let sortedProductsDsc;
    sortedProductsDsc = newArrivals.sort((a, b) => {
      return (
        parseInt(a.fld_website_discount_price) -
        parseInt(b.fld_website_discount_price)
      );
    });
    setNewArrivals(sortedProductsDsc)
  };
  const SortByAlphabeticallyAtoZ = () => {
    setNewArrivals(newArrivals.sort((a, b) =>
      a.fld_variantname > b.fld_variantname ? 1 : -1
    ),)
  };
  const SortByAlphabeticallyZtoA = () => {
    setNewArrivals(newArrivals.sort((a, b) =>
      b.fld_variantname > a.fld_variantname ? 1 : -1
    ),)
  };
  return (
    <>
      <section>
        <HeroBanner />
        <Container>
          <div class="align-items-center justify-content-center mb-5 row">
            <div class="text-center col-lg-12">
              <div class="shadow filter-card card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center filter-bar card-text gap-lg-5">
                    <div class="filter-bar row w-100">
                      <div className="col-6 col-md-2 col-lg-4 col-xl-3 order-2 order-md-1 d-flex mt-3 mt-md-0">
                        <span className="sorting-option me-2 align-items-center d-none d-lg-flex">Sort by</span>
                        <span className="sorting-option me-2 d-flex align-items-center d-block d-lg-none"
                          onClick={handleShow}
                        >Sort by <BiSolidChevronDownCircle className=" ms-2" />
                        </span>
                        <span className="d-none d-lg-block">
                          <Form.Select aria-label="Featured"
                            value={filter}
                            onChange={(text) => {
                              setFilter(text.target.value)
                              if (
                                text.target.value == "Price, High to Low"
                              ) {
                                SortByPriceHighToLow();
                              } else if (
                                text.target.value == "Price, Low to High"
                              ) {
                                SortByPriceLowToHigh();
                              } else if (
                                text.target.value ==
                                "Alphabetically, A to Z"
                              ) {
                                SortByAlphabeticallyAtoZ();
                              } else if (
                                text.target.value ==
                                "Alphabetically, Z to A"
                              ) {
                                SortByAlphabeticallyZtoA();
                              }
                              // else if (text.target.value == "Sort By") {
                              //   PostApiCall.postRequest(
                              //     {
                              //       subcategoryid: parseInt(
                              //         this.props.match.params.categoryid
                              //       ),
                              //       stateid: 0,
                              //     },
                              //     "GetProductsCatgory"
                              //   ).then((results) =>
                              //     // const objs = JSON.parse(result._bodyText)
                              //     results.json().then((obj1) => {
                              //       if (
                              //         results.status == 200 ||
                              //         results.status == 201
                              //       ) {
                              //         // console.log(obj1.data)
                              //         this.setState({
                              //           Items: obj1.data.filter(value => value.fld_status == "Active"),
                              //         });
                              //       }
                              //     })
                              //   );
                              // }
                            }
                            }
                          >
                            {filterType.map((data) => (
                              <option key={data.value} value={data.value}>
                                {data.label}
                              </option>
                            ))}
                          </Form.Select>
                        </span>
                      </div>
                      <div className="position-relative col-md-7 col-lg-5 col-xl-7 order-1 order-md-2">
                        <input
                          type="text"
                          className="form-control ps-5"
                          value={searchInput}
                          onChange={(e) => {
                            setSearchInput(e.target.value);
                          }}
                          placeholder="Search for bedsheets, bedspreads and cushion covers "
                        ></input>
                        <BiSearch className="search-icon" />
                      </div>
                      <div class="total-products justify-content-start justify-content-lg-center mt-3 mt-md-0 col-6 col-md-3 col-xl-2 order-3 order-md-3">
                        <span>&nbsp;Total Products -&nbsp;</span>
                        <span> {filteredData.length} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {filteredData.length > 0 ? (
            <Row>
              {filteredData.map((productdata) => {
                return (
                  <Col lg={4} className="mb-4">
                    <Card1
                      product={productdata}
                      showprice="true"
                      showshortdescription="true"
                      showitemname="true"
                      showaddrtocart="true"
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <>
              <Row className="justify-content-center align-items-center mb-5">
                <Col lg={12} className="text-center">
                  <h4>We couldn't find any matches!</h4>
                  <h6>Please try searching something else</h6>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </section>

      <Offcanvas show={show} onHide={handleClose} placement="bottom" key="bottom-1">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="sorting-offcanvas">SORT BY</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0 py-0" />
        <Offcanvas.Body>
          {filterType.map((data) => (
            <div className="d-flex my-2 justify-content-between fw-bold" value={sortButton} onClick={(e) => {
              setSortButton(data.value)
              setFilter(data.value)
              if (
                data.value == "Price, High to Low"
              ) {
                SortByPriceHighToLow();
              } else if (
                data.value == "Price, Low to High"
              ) {
                SortByPriceLowToHigh();
              } else if (
                data.value ==
                "Alphabetically, A to Z"
              ) {
                SortByAlphabeticallyAtoZ();
              } else if (
                data.value ==
                "Alphabetically, Z to A"
              ) {
                SortByAlphabeticallyZtoA();
              }
              handleClose()
            }}>
              <div key={data.value} value={data.value}>
                {data.label}
              </div>
              <Form.Check
                className="sorting-radio-button"
                name="group1"
                type="radio"
                // checked={sortButton =}
                id={`reverse-radio-2`}
              />
            </div>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default Productlist;
