import logo from "../assets/images/meluhafooterlogo.png";
import "./commingsoon.css";

function CommingSoon() {
  return (
    <div className="container-fluid main-box p-0 m-0">
      <div class="hero-content pt-5">
        <img src={logo} alt="Meluha Home" />
        <h1>Launching Soon</h1>
      </div>
    </div>
  );
}
export default CommingSoon;
