import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import PostApiCall from "../../Api/postApi";

// import Modal from '@mui/material/Modal';
import { store } from "../../context/store";
import "./Login.css";
var otp = Math.floor(100000 + Math.random() * 900000);
function Login(props) {
  const [profile, setProfile] = useState([]);
  const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [email, setEmail] = useState("");
  const [data, setData] = useState([]);
  const [password, setpassword] = useState("");
  const { cartItemBeforeLogin, setCartItemBeforeLogin } = useContext(store);
  const [Registration, setRegistration] = useState({ RegistrationData: "", RegistrationStatus: "", });
  const { ProductCart, setProductCart } = useContext(store);
  const { Cart, setCart } = useContext(store);
  const { cartamount, setcartamount } = useContext(store);
  const { openRegistration, setopenRegistration } = useContext(store)
  const [passwordeye, setpasswordeye] = useState(false);
  const { showLoginPopupWebsite, setLoginPopupWebsite } = useContext(store);
  const [ShowModal, setShowModal] = useState(false);
  const [ShowInputforOtp, setShowInputforOtp] = useState(false);
  const [forgetpass, setforgetpass] = useState(false);
  const [ShowEmailBox, setShowEmailBox] = useState(false);
  const [ShowOTPBox, setShowOTPBox] = useState(false);
  const [ShowPasswordBox, setShowPasswordBox] = useState(false);
  const [newpasswordeye, setnewpasswordeye] = useState(false);
  const [confirmpasswordeye, setconfirmpasswordeye] = useState(false);
  const [forgetForm, setforgetForm] = useState({ email: "", salt: "", otp: "", confirmotp: "", password: "", confirmpassword: "", });
  const { open, setOpen } = useContext(store);
  const { forgot, setForgot } = useContext(store);
  const handleCloseLoginPopup = () => setLoginPopupWebsite(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 5,
  };

  const handlepasswordclick = () => {
    setpasswordeye(!passwordeye);
  };

  const { register, handleSubmit, formState: { errors }, } = useForm();
  const getCartdetails = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderDetails");
    var Orderdetails = JSON.parse(Order);
    ////
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderDetails") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {

          setProductCart(res1.data);
          setCart((res1.data.reduce((a, v) => a = a + v.fld_quantity, 0)))
          setcartamount(res1.data[0].fld_cart_amount_preview)
        }
      });
  };
  const onSubmit = (data) => {
    Notiflix.Loading.dots();
    // ;
    PostApiCall.postRequest(
      {
        email: data.email,
        password: data.password,
        action: "Login",
        actiondate: moment().format("lll"),
      },
      "AddwebsiteLogin"
    ).then((results) =>
      results.json().then((obj) => {
        if (
          (results.status == 200 || results.status == 201)
          &&
          cartItemBeforeLogin !== null
        ) {
          localStorage.setItem("CustomerData", JSON.stringify(obj.data));
          setLoginPopupWebsite(false)
          // ===========with Login=========
          PostApiCall.postRequest(
            {
              orderdate: moment().format("lll"),
              itemdetails: `[{"Item":${cartItemBeforeLogin},"Quantity":${1}}]`,
              status: "INCART",
              customerid:
                localStorage.getItem("CustomerData") == null
                  ? null
                  : obj.data[0].fld_customerid,
              createdon: moment().format("lll"),
              updatedon: moment().format("lll"),
              orderid:
                localStorage.getItem("OrderDetails") == null || localStorage.getItem("OrderDetails") == ""
                  ? 0
                  : obj.data[0].order_id,
              updatedby:
                localStorage.getItem("CustomerData") == null
                  ? null
                  : obj.data[0].fld_customerid,
            },
            "AddToCartWebsite"
          ).then((result) =>
            result.json().then((obj) => {
              if (
                result.status == 200 ||
                result.status == 201
              ) {

                Notiflix.Loading.remove()
                //
                if (
                  localStorage.getItem("OrderDetails") == null || localStorage.getItem("OrderDetails") == ""
                ) {
                  //
                  localStorage.setItem(
                    "OrderDetails",
                    JSON.stringify(obj.data)
                  );
                  setLoginPopupWebsite(false)
                  getCartdetails();
                  setCartItemBeforeLogin(null)
                }
                // getCartdetails1();
              } else {
                Notiflix.Notify.failure(obj.data);
              }
            })
          );
          // onPostMoveCart(obj);
        } else if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove()
          localStorage.setItem("CustomerData", JSON.stringify(obj.data));
          // (window.location.href = "/");
          setLoginPopupWebsite(false)

          if (localStorage.getItem("currentPage") != null) {
            localStorage.getItem("currentPage") == "Cart"
              ? (window.location.href = "/checkout")
              : (window.location.href = "/");
          } else {
            localStorage.getItem("ReviewPage") == "ReviewPage"
              ? this.props.history.goBack()
              : (window.location.href = "/");
          }
        } else {
          Notiflix.Loading.remove()
          Notiflix.Notify.failure(obj.data);
        }
      })
    );

  };

  const sendConfirmation = () => {
    if (email != '') {
      if (EmailRegex.test(email)) {

        onConfirmation();

      }
      else {
        Notiflix.Notify.failure('Please enter valid Email Address.')
      }
    }
    else {
      Notiflix.Notify.failure('Please enter your Email.')
    }
  }


  const onConfirmation = () => {

    Notiflix.Loading.dots('Please wait...');
    PostApiCall.postRequest({
      OTP: otp,
      email: email,

    }, "ForgotPasswordMailer").then((result) => {
      Notiflix.Loading.remove();
      onPostSalt()
      setOpen(false);
      setShowInputforOtp(true)

    }
    )

  }

  const onPostSalt = () => {
    PostApiCall.postRequest(
      {
        email: email
      },
      "Get_SaltByEmailId_ForgotPassword"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data != null) {
            this.setState({
              Salt: obj.data,
              open: false,
              ShowInputforOtp: true
            }, () => {
              Notiflix.Notify.success('Your verification OTP is successfully sent to your Email Id.')

            })
          }
        }
        else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure('Email Id is not Registered')
        }


      }

      ))
  }


  return (
    <Modal show={showLoginPopupWebsite} onHide={handleCloseLoginPopup}>
      <Modal.Header closeButton>Login</Modal.Header>
      <Modal.Body>
        <form className="px-lg-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-center">
            <img className="login-logo" src={localStorage.getItem("logo")} />
          </div>

          <div style={{ display: ShowModal == true ? "none" : "block" }}>
            <div className="text-field">
              <FloatingLabel
                controlId="floatingInput"
                label="Email Address"
                className="mb-3"
              >
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  {...register("email", {
                    required: "Enter Email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors?.email}
                  helperText={errors?.email ? errors.email.message : null}
                />
              </FloatingLabel>
              {/* <TextField
                label="Email Address*"
                variant="outlined"
                className="text-field-input"
                {...register("email", {
                  required: "Enter Email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={!!errors?.email}
                helperText={errors?.email ? errors.email.message : null}
              /> */}
              <div className="position-relative">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Password"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="name@example.com"
                    id="outlined-basic"
                    label="Password*"
                    variant="outlined"
                    type={passwordeye === false ? "password" : "text"}
                    className="text-field-input"
                    {...register("password", {
                      required: "Please enter password",
                    })}
                    error={errors?.password}
                    helperText={
                      errors?.password ? errors.password.message : null
                    }
                  />
                </FloatingLabel>
                {/* <TextField
                  id="outlined-basic"
                  label="Password*"
                  variant="outlined"
                  type={passwordeye === false ? "password" : "text"}
                  className="text-field-input"
                  {...register("password", {
                    required: "Please enter password",
                  })}
                  error={errors?.password}
                  helperText={errors?.password ? errors.password.message : null}
                /> */}
                <div
                  style={{
                    position: "absolute",
                    //bottom: errors?.password ? "50px" : "25px",
                    left: "calc(100% - 40px)",
                    top: "50%",
                    left: "calc(100% - 40px)",
                    transform: "translateY(-50%)",
                  }}
                >
                  {!passwordeye ? (
                    <AiFillEyeInvisible
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={handlepasswordclick}
                    />
                  ) : (
                    <AiFillEye
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={handlepasswordclick}
                    />
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <small
                  className="text-dark cursor-pointer"
                  onClick={() => {
                    // window.location.href = "/forget-password"
                    setShowModal(true);
                    setForgot(true);
                  }}
                >
                  Forgot your Password?
                </small>
              </div>

              <Button
                variant="contained"
                // className="btn-login-modal mt-3 buttoncolor"
                className="btn btn-theme mt-3"
                type="submit"
              >
                Login
              </Button>
              <div>
                <small className="register mt-4 mb-0">
                  <span>Don’t have an account?</span>
                  <span
                    onClick={() => {
                      setopenRegistration(true);
                      setLoginPopupWebsite(false);
                    }}
                    className="modalpage  mx-1 hyperlink"
                    style={{ cursor: "pointer" }}
                  >
                    Create an account
                  </span>
                </small>
              </div>
            </div>
          </div>
        </form>

        <div
          style={{ display: ShowModal == true ? "block" : "none" }}
        >
          <p className="text-center">
            Please enter your registered email address. An email notification
            with an OTP will then be sent to you.
          </p>
          <FloatingLabel
            controlId="floatingInput"
            label="Email Address"
            className="mb-3"
          >
            <Form.Control
              label="Email Address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              fullWidth
            ></Form.Control>
          </FloatingLabel>

          {/* <TextField
            label="Enter Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            fullWidth
          /> */}

          <Button
            variant="contained"
            className="btn btn-theme w-100"
            onClick={() => {
              sendConfirmation();
            }}
          >
            Send OTP
          </Button>
        </div>

        {/* } */}

        {ShowOTPBox && (
          <div className="row m-0 mt-2 justify-content-between align-items-center">
            <p className="ps-0 col-12">
              Please enter the OTP below to verify your email address.
            </p>

            <FloatingLabel>
              <Form.Control
                label="Enter OTP"
                className="col-md-8"
                onChange={(e) =>
                  setforgetForm({ ...forgetForm, confirmotp: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>

            {/* <TextField
              label="Enter OTP"
              className="col-md-8"
              onChange={(e) =>
                setforgetForm({ ...forgetForm, confirmotp: e.target.value })
              }
            /> */}

            <button
              className="col-auto btn btn-primary mt-md-0 mt-3 buttoncolor"
              onClick={() => {
                if (forgetForm.confirmotp != "") {
                  if (forgetForm.otp == forgetForm.confirmotp) {
                    // GetSalt()
                    setShowPasswordBox(true);
                    setShowOTPBox(false);
                  } else {
                    Notiflix.Notify.failure(
                      "You OTP does not match. Please Enter Correct OTP. "
                    );
                  }
                } else {
                  Notiflix.Notify.failure("Please Enter OTP.");
                }
              }}
            >
              Verify OTP
            </button>
          </div>
        )}

        {ShowPasswordBox && (
          <div className="row m-0  justify-content-between align-items-center">
            <div className="col-12 p-0 mt-3 position-relative">
              <FloatingLabel>
                <Form.Control
                  label="New Password"
                  type={newpasswordeye ? "text" : "password"}
                  fullWidth
                  onChange={(e) => {
                    setforgetForm({ ...forgetForm, password: e.target.value });
                  }}
                ></Form.Control>
              </FloatingLabel>
              {/* <TextField
                label="New Password"
                type={newpasswordeye ? "text" : "password"}
                fullWidth
                onChange={(e) => {
                  setforgetForm({ ...forgetForm, password: e.target.value });
                }}
              /> */}

              <div style={{ position: "absolute", top: "15px", right: "20px" }}>
                {!newpasswordeye ? (
                  <AiFillEyeInvisible
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={() => setnewpasswordeye(true)}
                  />
                ) : (
                  <AiFillEye
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={() => setnewpasswordeye(false)}
                  />
                )}
              </div>
            </div>

            <div className="col-12 p-0 mt-3 position-relative">
              <FloatingLabel>
                <Form.Control
                  label="Confirm Password"
                  type={confirmpasswordeye ? "text" : "password"}
                  fullWidth
                  onChange={(e) => {
                    setforgetForm({
                      ...forgetForm,
                      confirmpassword: e.target.value,
                    });
                  }}
                ></Form.Control>
              </FloatingLabel>
              {/* <TextField
                label="Confirm Password"
                type={confirmpasswordeye ? "text" : "password"}
                fullWidth
                onChange={(e) => {
                  setforgetForm({
                    ...forgetForm,
                    confirmpassword: e.target.value,
                  });
                }}
              /> */}

              <div style={{ position: "absolute", top: "15px", right: "20px" }}>
                {!confirmpasswordeye ? (
                  <AiFillEyeInvisible
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={() => setconfirmpasswordeye(true)}
                  />
                ) : (
                  <AiFillEye
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={() => setconfirmpasswordeye(false)}
                  />
                )}
              </div>
            </div>

            <button
              className="col-auto btn btn-primary mt-3 buttoncolor"
              onClick={() => { }}
            >
              Confirm Password
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default Login